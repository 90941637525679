// Use this to write your custom SCSS
.display {
  display: none;
  background: #0f3d81;

}

.category-container {
  background-color: #f8eefa;
  width: 1000px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  padding: 1rem 2rem;

}

@media screen and (max-width: 900px) {
  .category-container {
    display: none;
  }
}

.category-container2 {
  display: flex;
  align-items: center;
  justify-content: space-around;

  h5 {
    margin-top: 0.5rem;
  }
}

.icon-container-size {
  width: 200px;
  height: 160px;
  color: #343f52;
  border-radius: 0.4rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.icon-size {
  width: 3rem;
  height: 3rem;
}

.href-container {
  width: 210px;
  height: 210px;
}

@media screen and(max-width: 900px) {
  .mega-menu-content {
    display: none;
  }
}

.login-container-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 15px;
}

@media screen and(min-width: 900px) {
  .login-container-div {
    display: none;
  }
}

.footer-container {
  justify-content: space-around;
  align-items: baseline;
}

.icon-center {
  text-align: center;
}

.header-container {
  height: 70px;
}

.align-center {
  align-self: center;
}

.card-max-height {
  max-height: 265px;
  min-height: 255px;
}

.nav-height {
  height: 90px;
}

.card-description-line-height {
  min-height: 65px;
  max-height: 65px;
}

.box-center {
  justify-content: center;
}

.card-body {
  padding: 1rem 1rem !important;
}

@media screen and(max-width: 450px) {
  .display-6 {
    font-size: 1.15rem !important;
  }
  .card-body {
    padding: 1rem 1rem !important;
  }
  .card-max-height {
    min-height: 218px;
    max-height: 218px;
  }
  h4 {
    font-size: 0.85rem !important;
  }
}

.margin-bottom {
  margin-bottom: 40px;
}

.footer-logo-margin {
  margin-left: -8px;
}

.navbar-write-logo-margin {
  margin-left: -20px;
}

@media screen and (max-width: 450px) {
  .mobile-category-container-view {
    display: inline;
  }
  .category-container-view {
    display: none;
  }
}

@media screen and (min-width: 450px) {
  .mobile-category-container-view {
    display: none;
  }
  .category-container-view {
    display: flex;
  }
}


#ot-sdk-btn-floating {
  visibility: hidden;
}

.container-radius {
  border-radius: 10px;
}

